import { Box, Typography, useTheme } from '@mui/material'
import { usePlanCodes } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/usePlanCodes'
import { usePurchasePageEvent } from '@talentinc/gatsby-theme-ecom/hooks/useCheckoutEvent'
import useDiscountToken from '@talentinc/gatsby-theme-ecom/hooks/useDiscountToken'
import React, { useEffect, useState } from 'react'

import { CircularProgress } from '@mui/material'
import '@talentinc/gatsby-theme-ecom/../../../common-styles/braintree-overrides.css'
import { navigate } from 'gatsby'
import { SPINNER_SIZE } from '../../types/widget'
import { getPurchaseStatus } from './api'
import { useWindow } from '../../hooks/useWindow'


const MAX_POLL_ATTEMPTS = 5
const POLL_INTERVAL_IN_MS = 1000
const MAX_POLL_INTERVAL_IN_MS = 5000

export const ProcessingPaymentPage = () => {
  const theme = useTheme()
  const planCodes = usePlanCodes()
  const urlDiscountToken = useDiscountToken()
  const windowObject = useWindow()

  const [pollAttemptsLeft, setPollAttemptsLeft] = useState<number>(MAX_POLL_ATTEMPTS)
  const [currentPollInterval, setCurrentPollInterval] = useState<number>(0)
  const [url, setUrl] = useState<URL | null>()

  usePurchasePageEvent({
    planCodes: planCodes,
    discountToken: urlDiscountToken,
  })

  useEffect(() => {
    if(!windowObject) return
    setUrl(new URL(windowObject.location.toString()))
  }, [windowObject])

  useEffect(() => {
    if(!url) return

    const purchaseId = url.searchParams.get('purchaseId')
    const userName = url.searchParams.get('name')
    const redirectUrl = url.searchParams.get('redirectUrl')

    const failedUrl = '/purchase/failed?plan=' + url.searchParams.get('plan') + '&name=' + userName
    const successUrl = `/purchase/successful${redirectUrl ? '?redirectUrl=' + redirectUrl : ''}`

    if(!purchaseId) return

    const polling = async (attemptsLeft: number) => {
      if(attemptsLeft <= 0) {
        navigate(failedUrl)
        return
      }

      try {
        const purchaseStatusResponse = await getPurchaseStatus(purchaseId)
        const purchaseStatus = purchaseStatusResponse?.data?.status?.toLowerCase()

        if(!purchaseStatus || purchaseStatus === 'pending') {
          const newAttemptsLeftCount = attemptsLeft - 1
          let nextPollCallIn
          setCurrentPollInterval((prevInterval: number) => {
            nextPollCallIn = Math.min(prevInterval + POLL_INTERVAL_IN_MS, MAX_POLL_INTERVAL_IN_MS)
            return nextPollCallIn
          })
          setPollAttemptsLeft(newAttemptsLeftCount)
          setTimeout(() => polling(newAttemptsLeftCount), nextPollCallIn)
        } else {
          const newUrl = purchaseStatus === 'authorised' ? successUrl : failedUrl
          navigate(newUrl)
          return
        }
      } catch(e) {
        console.error('An error occurred while trying to get the purchase status', e)
        navigate(failedUrl)
        return
      }
    }

    polling(pollAttemptsLeft)
  }, [url])

  return (
    <Box
      pt={3}
      pb={10}
      sx={{
        height: '100%',
        minHeight: '60vh',
        maxWidth: 1086,
        width: '95%',
        margin: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center',

        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
        },
      }}
    >
      <Box
        p={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          background: 'white',
          borderRadius: '8px',

          [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
          },
        }}
      >
        <CircularProgress
          color="primary"
          size={SPINNER_SIZE}
          style={{marginBottom: '8px'}}
        />

        <Typography variant="h2">Please wait...</Typography>

        <Typography variant="body1">
          Please hold on as we're processing your payment. It should take a few seconds
        </Typography>
      </Box>
    </Box>
  )
}
