import { useEffect, useState } from 'react'

export const useWindow = () => {
  const [windowObject, setWindowObject] = useState(
    typeof window === 'object' ? window : undefined
  )

  useEffect(() => {
    if (typeof window === 'object') setWindowObject(window)
  }, [])

  return windowObject
}
